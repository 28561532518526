<template>
    <div>
        <b-row>
            <b-col md="6" lg="4" class="mb-3">
                <h4 class="mb-2">Background <tooltip-with-image img="/img/tv/background.png"/></h4>
                <BackgroundSelector ref="BackgroundSelectorBackground" v-model="config.channel_select.background" :background-options="[0, 1]"/>
            </b-col>
            <b-col md="6" lg="4" class="mb-3">
                <h4 class="mb-2">Channel card background <tooltip-with-image img="/img/tv/cd_background.png"/></h4>
                <BackgroundSelector ref="BackgroundSelectorChannel" v-model="config.channel_select.channel_card_background" :background-options="[0, 1]"/>
            </b-col>
            <b-col md="6" lg="4" class="mb-3">
                <b-form-group>
                    <label>Text color: <fa class="ml-1" icon="question-circle" v-b-tooltip="'Set color and opacity.'" /></label>
                    <ColorWithOpacitySelector v-model="config.channel_select.text"/>
                </b-form-group>
                <b-form-group>
                    <label>EPG Progress background color: <tooltip-with-image img="/img/tv/epg_prog_bg.png"/></label>
                    <ColorWithOpacitySelector v-model="config.channel_select.epg_progress_background"/>
                </b-form-group>
                <b-form-group>
                    <label>EPG Progress color: <tooltip-with-image img="/img/tv/epg_progress.png"/> </label>
                    <ColorWithOpacitySelector v-model="config.channel_select.epg_progress_progress"/>
                </b-form-group>
                <b-form-group>
                    <label>Divider color: <tooltip-with-image img="/img/tv/divider.png"/></label>
                    <ColorWithOpacitySelector v-model="config.channel_select.divider"/>
                </b-form-group>

            </b-col>
        </b-row>
    </div>
</template>
<script>

    import BackgroundSelector from '@/views/components/BackgroundSelector'
    import { BCol, BRow, BFormGroup } from 'bootstrap-vue'
    import ColorWithOpacitySelector from '@/views/components/ColorWithOpacitySelector'
    import TooltipWithImage from '@/views/components/TooltipWithImage'

    export default {
        components: {
            TooltipWithImage,
            ColorWithOpacitySelector,
            BRow,
            BCol,
            BFormGroup,
            BackgroundSelector
        },
        data() {
            return {

                config: {
                    channel_select: {
                        background: {},
                        channel_card_background: {},
                        text: {
                            color: '#ffffff',
                            opacity: 1.0
                        },
                        epg_progress_background: {
                            color: '#304a58',
                            opacity: 1.0
                        },
                        epg_progress_progress: {
                            color: '#11db95',
                            opacity: 1.0
                        },
                        divider: {
                            color: '#ffffff',
                            opacity: 0.25
                        }
                    }
                }
            }
        },
        methods: {
            getConfig() {
                return this.config
            },
            setConfig(config) {
                this.config = {...this.config, ...config}
            },
            valid() {
                if (!this.$refs.BackgroundSelectorBackground.valid()) return false
                else if  (!this.$refs.BackgroundSelectorChannel.valid()) return false
                return true
            }
        }

    }

</script>
